@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap");

* {
  box-sizing: border-box;
}

html {
  font-family: "Noto Sans JP", sans-serif;
}

body {
  margin: 0;
}

.forget-password {
  color: #0253ff;
  transition: 0.3s;
  margin-top: 10px;
  display: inline-block;
  cursor: pointer;
  opacity: 0.8;
}

.forget-password:hover {
  opacity: 1;
  text-decoration: underline;
}

::placeholder {
  color: #ccc !important;
}

.text-red {
  color: red !important;
  font-size: 12px !important;
}
.text-grey {
  color: #ccc !important;
}

.text-green {
  color: green !important;
}

.grey-option-one > option:first-child {
  color: #ccc !important;
}
